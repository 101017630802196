import '../../styles/App.css';

// Primera pantalla
function Result(props) {
  if(props.hidden){
    return '';
  }else{
    return (
      <div style={ props.right? {marginLeft :'30px'}: {}}>
        <div className="description" dangerouslySetInnerHTML={{ __html: props.text }}></div>
            <br />
            <div>
              {props.userPath.map((item, index) =>
                item.selectedOption.documentation ? (
                  <div key={index}>
                     <div dangerouslySetInnerHTML={{ __html: `<strong>${item.selectedOption.documentation[props.language].replace(/\n/g, '<br />')}</strong>` }}></div>
                  </div>
                ) : null
              )}
            </div>
            <br />
            {props.result.image && <img src={props.getMediaContent('image', props.result.image)} alt="Image" className="embedded-media" onClick={() => props.handleImageClick(props.getMediaContent('image', props.result.image))} />}
              {props.result.video && props.renderVideoEmbed(props.result.video)}
              {props.result.link[props.language] && (
                <a onClick={() => {
                  const link = decodeURIComponent(props.result.link[props.language]) || '#';
                  window.open(link, '_blank', 'noopener,noreferrer');
                }}>
                  <button className="button_start" dangerouslySetInnerHTML={{ __html: props.result.button[props.language] }}></button>
                </a>
              )}
              <a href=""><button className="button_start" onClick={props.resetFlow}>Reset</button></a>
      </div>
     
    );
  }
 
}

export default Result;

