import React, { useEffect, useRef, useState } from 'react';
import '../../styles/App.css';
import Modal from '../commons/Modal';
import { parseForm } from '../../utils/utils';
import ImageModal from '../commons/ImageModal';
import ContentQuestion from './ContentQuestion';
import ImageVideo from './ImageVideo';

function Question(props) {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const pageRef = useRef(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const imageSrc = props.getMediaContent('image', props.question.image);
  const handleImageClick = () => {
    if (imageSrc) {
      setSelectedImage(imageSrc);
      setIsImageModalOpen(true);
    }
  };

  const handleSendClick = () => {
    if (props.question.form) {
      setCurrentForm(props.question.form);
      setIsFormModalOpen(true);
    } else if (currentForm) {
      setIsFormModalOpen(true);
    } else {
      props.nextQuestionOrResult();
    }
  };

  const handleOptionClick = (option) => {
    if (option.form) {
      setCurrentForm(option.form);
    } else {
      setCurrentForm(null);
    }
    props.handleOptionClick(option);
  };

  useEffect(() => {
    if (pageRef.current) {
      const hasMedia = props.question.image || props.question.video;
      let mainClass = props.isDuplicate?'page_duplicate' : 'page';
      pageRef.current.className = mainClass + ' ' + (hasMedia ? 'page-with-media' : 'page page-no-media');
    }
  }, [props.question, props.language, props.isDuplicate]);

  const videoSrc = props.getMediaContent('video', props.question.video);

  return (
    <div ref={pageRef} className={props.isDuplicate?"page_duplicate":"page"}>
      <React.Fragment>
      <div className="container" style={{  display: 'flex',  gridArea:'main-question', diplay: 'flex', }}>
          <ContentQuestion
            language={props.language}
            question={props.question}
            divRef={props.divRef}
            selected={props.selected}
            hidden =  {false}
            hiddenButton={props.hiddenButton}
            textButton={props.textButton}
            handleSendClick={handleSendClick}
            handleOptionClick={handleOptionClick}
            letters={letters}
            handleImageClick = {handleImageClick}
            getMediaContent = {props.getMediaContent}
            isDuplicate={props.isDuplicate}
          />
          <ContentQuestion
           language={props.subLanguage}
            question={props.question}
            divRef={props.divRef}
            selected={props.selected}
            hidden =  {!props.isDuplicate}
            hiddenButton={props.hiddenButton}
            textButton={props.subTextButton}
            handleSendClick={handleSendClick}
            handleOptionClick={handleOptionClick}
            letters={letters}
            handleImageClick = {handleImageClick}
            getMediaContent = {props.getMediaContent}
            isDuplicate={props.isDuplicate}
          />
      </div>
      <ImageVideo
           imageSrc = {imageSrc}
           alt="Current option"
           className="embedded-media"
           onClick={props.handleImageClick}
           videoSrc = {videoSrc}
           renderVideoEmbed = {props.renderVideoEmbed}
           hidden = {props.isDuplicate}
        />
        <div className="footer_q"></div>
        <button
          className="button_reset"
          onClick={props.resetFlow}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          Reset
        </button>
      </React.Fragment>
      {/* Form Modal */}
      <Modal
        isOpen={isFormModalOpen}
        onClose={() => setIsFormModalOpen(false)}
        onSubmit={props.handleFormSubmit}
        formFields={parseForm(currentForm, props.language)}
        formButton={props.textButton}
        isDuplicate={props.isDuplicate}
        subFormFields= {props.isDuplicate? parseForm(currentForm, props.subLanguage):null}
        subTextButton={props.subTextButton}
      />
      {/* Image Modal */}
      <ImageModal
        imageSrc={selectedImage}
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
      />
    </div>
  );
}

export default Question;

