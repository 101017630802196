import React, { useEffect } from 'react';
import { execScript } from '../../utils/utils';
import '../../styles/App.css';
import ImageVideo from './ImageVideo';

// Primera pantalla
function ContentQuestion(props) {

  
  const imageSrc = props.getMediaContent('image', props.question.image);
  const videoSrc = props.getMediaContent('video', props.question.video);

  if(props.hidden){
    return '';
  }else{
    return (
      <div className="box" >
      <div className="main-question">
        <div className="question-title">
          <span dangerouslySetInnerHTML={{ __html: props.question.text[props.language] }}></span>
        </div>
        {props.question.description && props.question.description[props.language] && (
          <div className="question-description" dangerouslySetInnerHTML={{ __html: props.question.description[props.language] }}></div>
        )}
        {props.question.options.map((option, index) => (
          <button
            id={`option_${props.question.id}_${index}`}
            key={`option_${props.question.id}_${index}`}
            ref={(ref) => (props.divRef.current[index] = ref)}
            className={`question-button ${props.selected === option ? 'intermitente' : ''}`}
            onClick={() => props.handleOptionClick(option)}
          >
            <span className="letter">{props.letters[index]}</span>{option.text[props.language]}
          </button>
        ))}
        <div>
          <button className="button_start" onClick={props.handleSendClick} hidden={props.hiddenButton}>
            {props.textButton}
          </button>
        </div>
        <ImageVideo
           imageSrc = {imageSrc}
           alt="Current option"
           className="embedded-media"
           onClick={props.handleImageClick}
           videoSrc = {videoSrc}
           renderVideoEmbed = {props.renderVideoEmbed}
           hidden = {!props.isDuplicate}
        />
      </div>

</div>
  );
  }
  
}

export default ContentQuestion;

