import '../../styles/App.css';

// Primera pantalla
function ImageVideo(props) {

  
  if(props.hidden){
    return '';
  }else{
    return (
      <div className="multimedia">
        {props.imageSrc && (
          <img
            src={props.imageSrc}
            alt="Current option"
            className="embedded-media"
            onClick={props.handleImageClick}
          />
        )}
        {props.videoSrc && props.renderVideoEmbed(props.videoSrc)}
      </div>
  );
  }
  
}

export default ImageVideo;

