import React, { useRef, useState, useEffect } from 'react';
import './styles/App.css';
import Question from './components/question/Question';
import Home from './components/home/Home';
import Modal from './components/commons/Modal';
import ImageModal from './components/commons/ImageModal';
import { execScript, parseForm } from './utils/utils';
import { BrowserRouter as Router } from 'react-router-dom';
import Result from './components/result/Result';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const logo = 'logo.png';
  const viewportWidth = urlParams.get('viewportWidth');
  const viewportHeight = urlParams.get('viewportHeight');
  const questionId = urlParams.get('question');


  if (viewportWidth && viewportHeight) {
    const appRoot = document.getElementById('root');
    appRoot.style.width = `${viewportWidth}px`;
    appRoot.style.height = `${viewportHeight}px`;
  }
  const [data, setData] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [result, setResult] = useState(null);
  const [language, setLanguage] = useState(null);
  const [subLanguage, setSubLanguage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [main, setMain] = useState(true);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [selected, setSelected] = useState(null);
  const [textButton, setTextButton] = useState('');
  const [subTextButton, setSubTextButton] = useState('');
  const [userPath, setUserPath] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  const [showDuplicateButton, setShowDuplicateButton] = useState(false);
  const [dualMode, setDualMode] = useState(true);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formFields, setFormFields] = useState([]);

  const divRef = useRef([]);
  const questionHistory = useRef([]);
  const currentIndex = useRef(0);

  const getBasename = () => {
    const pathName = window.location.pathname;
    const firstSlashIndex = pathName.indexOf('/', 1);
    if (firstSlashIndex !== -1) {
      return pathName.substring(0, firstSlashIndex);
    }
    return '/';
  };

  const basename = getBasename();

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };
  const handleSubLanguageChange = (lang) => {
    setSubLanguage(lang);
  };

  const handleOptionClick = (option) => {
    setSelected(option);
    if (option.form) {
      setFormFields(parseForm(option.form, language));
    } else {
      setFormFields([]);
    }
  };

  const resetFlow = () => {
    localStorage.clear();
    sessionStorage.clear();
    setCurrentQuestion(data.questions[0]);
    setSelected(null);
    setUserPath([]);
    questionHistory.current = [];
    currentIndex.current = 0;
    setResult(null);
    setIsFormModalOpen(false);
    setIsImageModalOpen(false);
    setIsDuplicate(false);
    setSubLanguage(language);
    setSubTextButton(textButton)
    setMain(true);
  };

  const nextQuestionOrResult = () => {
    if (selected) {
      if ('nextQuestion' in selected) {
        const nextQuestion = data.questions.find(q => q.id === selected.nextQuestion);
        setCurrentQuestion(nextQuestion);
        setSelected(null);
        setMain(false);
        const scriptToExecute = selected.script && (selected.script[language] || Object.values(selected.script)[0]);
        if (scriptToExecute) {
          execScript(scriptToExecute);
        }
      } else if ('result' in selected) {
          const scriptToExecute = selected.script && (selected.script[language] || Object.values(selected.script)[0]);
          if (scriptToExecute) {
            execScript(scriptToExecute);
          }
        const selectedResult = data.results.find(r => r.id === selected.result);
        if (selectedResult) {
          if (selectedResult.form) {
            setFormFields(parseForm(selectedResult.form, language));
            setResult(null);
            setIsFormModalOpen(true);
          } else {
            setResult(selectedResult);
            setCurrentQuestion(null);
            setSelected(null);
            setMain(false);
          }
        } else {
          console.error("Result not found");
        }
      }
      setUserPath([...userPath, { questionId: currentQuestion.id, selectedOption: selected }]);
      questionHistory.current.push(currentQuestion);
      currentIndex.current += 1;
    }
  };

  const handleFormSubmit = (formData) => {
    Object.entries(formData).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });
    setIsFormModalOpen(false);
    nextQuestionOrResult();
  };

  const handleImageClick = (imageSrc) => {
    if (imageSrc) {
      setSelectedImage(imageSrc);
      setIsImageModalOpen(true);
    }
  };

  const renderVideoEmbed = (url) => {
    let embedUrl;
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const videoId = url.split('v=')[1] || url.split('/').pop();
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
    }
    return (
      <iframe
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded video"
        className="embedded-media"
      ></iframe>
    );
  };

  const getMediaContent = (mediaType, mediaData) => {
    if (!mediaData) return null;

    const mediaKeys = Object.keys(mediaData);
    if (mediaKeys.length === 1) {
      return mediaData[mediaKeys[0]];
    } else {
      return mediaData[language];
    }
  };

  
        //duplicate
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

  useEffect(() => {
    // Cargar el archivo config.json desde la carpeta public
    fetch('/config/config.json')
      .then(responseConfig => responseConfig.json())
      .then(config => {
        setDualMode(config.dualMode);
        window.addEventListener('resize', handleResize);
        const flow = config.defaultDiagram || urlParams.get('flow') || 'flow.json';
        fetch(flow)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            setData(data);
            let defaultLanguage = urlParams.get('defaultLanguage') || Object.keys(data.languages)[0] || 'ca';
            const languageExists = data.languages.hasOwnProperty(config.defaultLanguage);
            if(languageExists)defaultLanguage = config.defaultLanguage;
            setLanguage(defaultLanguage);
            setSubLanguage(defaultLanguage);
    
            const initialQuestion = questionId
              ? data.questions.find(q => q.id === questionId)
              : data.questions[0];
          
    
            if (!initialQuestion) {
              // Manejar el caso donde el questionId no es válido
              console.error(`Question with ID ${questionId} not found.`);
              setMain(true); // Volver a la página de inicio o mostrar un mensaje de error
            } else {
              setCurrentQuestion(initialQuestion);
              setMain(questionId ? false : true);
            }
            setIsLoading(false);
          }).catch(error => {
            console.error('Error al obtener el flujo:', error);
          });
      })
      .catch(error => console.error('Error loading config:', error));



      return () => {
        window.removeEventListener('resize', handleResize);
      };
  }, []);

  useEffect(() => {
    setTextButton(selected ? data?.home?.sendButton[language] : '');
    setHiddenButton(!selected);
    if(subLanguage!=''){
      setSubTextButton(selected ? data?.home?.sendButton[subLanguage] : '');
    }
  }, [selected]);

  useEffect(() => {
    setShowDuplicateButton(dualMode && isLargeScreen);
  }, [isLargeScreen, dualMode]);
  
  useEffect(() => {
    if (result) {
      const scriptToExecute = result.script && (result.script[language] || Object.values(result.script)[0]);
      if (scriptToExecute) {
        execScript(scriptToExecute);
      }
    }
  }, [result, language]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const goToTest = () => {
    setMain(false);
  };
  const manageDuplicate = () => {
    setIsDuplicate(!isDuplicate);
  };

  if (main) {
      return(
        <Router basename={basename}>
        <div className="welcome">
          <Home
            showDuplicateButton={showDuplicateButton}
            home={data.home}
            langs={data.languages}
            language={language}
            subLanguage={subLanguage}
            isDuplicate={isDuplicate}
            isLargeScreen={isLargeScreen}
            goToTest={goToTest}
            handleLanguageChange={handleLanguageChange}
            handleSubLanguageChange={handleSubLanguageChange}
            logo={logo}
            getMediaContent={getMediaContent}
            renderVideoEmbed={renderVideoEmbed}
            handleImageClick={handleImageClick}
            handleDupButton={manageDuplicate}
          />
        </div>
      </Router>
      );   
  } else if (result && !isFormModalOpen) {
    return (
      <Router basename={basename}>
        <div className="welcome">
          <div className="header">
            <img src={`/${logo}`} data-qa="brand-logo" className="logo" alt="" />
          </div>
          <div className="principal">
            <div className={isDuplicate && isLargeScreen?"welcome_content_duplicate":"welcome_content"}>
                <div className="container" style={{  display: 'flex',  justifyContent: 'space-between', gridArea:'main-question', diplay: 'flex', }}>
                    <Result
                       text={result.text[language]}
                       userPath = {userPath}
                       language = {language}
                       result = {result}
                       hidden = {false}
                       handleImageClick = {handleImageClick}
                       getMediaContent = {getMediaContent}
                       resetFlow = {resetFlow}
                       renderVideoEmbed = {renderVideoEmbed}
                       right = {false}
                      />
                   <Result
                       hidden={!isDuplicate || !isLargeScreen}
                       text={result.text[subLanguage]}
                       userPath = {userPath}
                       language = {subLanguage}
                       handleImageClick = {handleImageClick}
                       result = {result}
                       getMediaContent = {getMediaContent}
                       resetFlow = {resetFlow}
                       renderVideoEmbed = {renderVideoEmbed}
                       right = {true}
                      />
                </div>
            </div>
          </div>
        </div>
      </Router>
    );
  } else {

    return (
      <Router basename={basename}>
        <React.Fragment>
          <div className="header">
            <img src={`/${logo}`} data-qa="brand-logo" className="logo" alt="" />
          </div>
          <Question
            question={currentQuestion}
            handleOptionClick={handleOptionClick}
            language={language}
            subLanguage={subLanguage}
            divRef={divRef}
            hiddenButton={hiddenButton}
            textButton={textButton}
            nextQuestionOrResult={nextQuestionOrResult}
            selected={selected}
            resetFlow={resetFlow}
            handleFormSubmit={handleFormSubmit}
            getMediaContent={getMediaContent}
            renderVideoEmbed={renderVideoEmbed}
            handleImageClick={handleImageClick}
            isDuplicate={isDuplicate && isLargeScreen}
            subTextButton={subTextButton}
          />
          {/* Form Modal */}
          <Modal
            isOpen={isFormModalOpen}
            onClose={() => setIsFormModalOpen(false)}
            onSubmit={handleFormSubmit}
            formFields={formFields}
            language={language}
            formButton={textButton}
          />
          {/* Image Modal */}
          <ImageModal
            imageSrc={selectedImage}
            isOpen={isImageModalOpen}
            onClose={() => setIsImageModalOpen(false)}
          />
        </React.Fragment>
      </Router>
    );
  }
}

export default App;

