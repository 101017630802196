import React, { useEffect, useState } from 'react';
import '../../styles/App.css';
import Modal from '../commons/Modal';
import { parseForm, execScript } from '../../utils/utils';
import ImageModal from '../commons/ImageModal';
import ContentHome from './ContentHome';

// Primera pantalla
function Home(props) {
  const [isModalOpen, setIsModalOpen] = useState(false); // For the form modal
  const [isImageModalOpen, setIsImageModalOpen] = useState(false); // For the image modal
  const [selectedImage, setSelectedImage] = useState(null);

  const handleStartClick = () => {
    if (props.home.form) {
      setIsModalOpen(true); // Open the form modal
    } else {
      props.goToTest();
    }
  };

  const handleFormSubmit = (formData) => {
    Object.entries(formData).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });
    props.goToTest();
  };

  useEffect(() => {
    const scriptToExecute = props.home.script && (props.home.script[props.language] || Object.values(props.home.script)[0]);
    if (scriptToExecute) {
      execScript(scriptToExecute);
    }
  }, [props.home, props.language]);

  return (
    <React.Fragment>
      <div className="header">
        <img src={"/" + props.logo} data-qa="brand-logo" className="logo" alt=""/>
        <button
           className="button_start"
          style={{ position: 'absolute', top: '10px', right: '20px' }}
          hidden={!props.showDuplicateButton}
          onClick={props.handleDupButton}
        >
          {props.isDuplicate?"No duplicate":"Duplicate"}
        </button>
      </div>
      <div className="principal">
        <div className={props.isDuplicate && props.isLargeScreen?"welcome_content_duplicate":"welcome_content"}>
        <div className="container" style={{  display: 'flex',  justifyContent: 'space-between'}}>
          <ContentHome
            home={props.home}
            langs={props.langs}
            language={props.language}
            goToTest={props.goToTest}
            handleLanguageChange={props.handleLanguageChange}
            getMediaContent={props.getMediaContent}
            renderVideoEmbed={props.renderVideoEmbed}
            handleImageClick={props.handleImageClick}
            setSelectedImage = {setSelectedImage}
            setIsImageModalOpen = {setIsImageModalOpen}
            isDuplicate={props.isDuplicate && props.isLargeScreen}
            hidden =  {false}
            left= {true}
          />
           <ContentHome
            home={props.home}
            langs={props.langs}
            language={props.subLanguage}
            goToTest={props.goToTest}
            handleLanguageChange={props.handleSubLanguageChange}
            getMediaContent={props.getMediaContent}
            renderVideoEmbed={props.renderVideoEmbed}
            handleImageClick={props.handleImageClick}
            hidden =  {!props.isDuplicate || !props.isLargeScreen}
            setSelectedImage = {setSelectedImage}
            setIsImageModalOpen = {setIsImageModalOpen}
            isDuplicate={props.isDuplicate && props.isLargeScreen}
            left = {false}
          />
        </div>

        </div>
      </div>
      <div className="footer">
        <div className="front_footer">
          <button
            key="comencar"
            className="button_start"
            onClick={handleStartClick}
          >
            {props.home.startButton[props.language]}
          </button>
        </div>
      </div>
      {/* Form Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleFormSubmit}
        formFields={props.home.form ? parseForm(props.home.form, props.language) : []}
        language={props.language}
        formButton={props.home.sendButton[props.language]}
      />
      {/* Image Modal */}
      <ImageModal 
        imageSrc={selectedImage} 
        isOpen={isImageModalOpen} // Independent state for the image modal
        onClose={() => setIsImageModalOpen(false)} // Close image modal separately
      />
    </React.Fragment>
  );
}

export default Home;




