import React, { useEffect } from 'react';
import { execScript } from '../../utils/utils';
import '../../styles/App.css';

// Primera pantalla
function ContentHome(props) {

  const imageSrc = props.getMediaContent('image', props.home.image);

  const handleImageClick = () => {
    if (imageSrc) {
      props.setSelectedImage(imageSrc);
      props.setIsImageModalOpen(true); // Open the image modal
    }
  };

  const keys = Object.keys(props.langs).filter(key => key !== props.language);

  useEffect(() => {
    const scriptToExecute = props.home.script && (props.home.script[props.language] || Object.values(props.home.script)[0]);
    if (scriptToExecute) {
      execScript(scriptToExecute);
    }
  }, [props.home, props.language]);

  const videoSrc = props.getMediaContent('video', props.home.video);

  if(props.hidden){
    return '';
  }else{
    return (
      <div className="box" style={props.isDuplicate && !props.left?{ width: '50%', marginLeft: '30px' }: props.isDuplicate?{ width: '50%' }:{}}>
            <div className="title">{props.home.title[props.language]}</div>
            {keys.length > 0 && (
              <div className="language-selector">
                {keys.map((lang, index) => (
                  <em className="language-div" key={lang + index}>
                    <button
                      key={lang}
                      className="language-button"
                      onClick={() => props.handleLanguageChange(lang)}
                    >
                      {props.langs[lang]}
                    </button>
                    <span hidden={index === keys.length - 1}> / </span>
                  </em>
                ))}
              </div>
            )}
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: Array.isArray(props.home.description[props.language])
                  ? props.home.description[props.language].join('')
                  : props.home.description[props.language]
              }}
            ></div>

            {imageSrc && <img src={imageSrc} className="embedded-media" onClick={handleImageClick} />}
            {videoSrc && props.renderVideoEmbed(videoSrc)}
      </div>
  );
  }
  
}

export default ContentHome;

