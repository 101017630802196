import React from 'react';
import '../../styles/Modal.css'; // Reuse modal styles

function ImageModal({ imageSrc, isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content image-modal" onClick={(e) => e.stopPropagation()}>
        <img src={imageSrc} alt="Enlarged view" className="modal-image" />
        <button className="modal-close" onClick={onClose}>X</button>
      </div>
    </div>
  );
}

export default ImageModal;

